<template>
  <div class="mx-2">
    <StudentCarousel
      v-if="section"
      :section_id="section.id"
      @student_selcted="slotStudentSelected"
    ></StudentCarousel>

    <div>
      <template v-if="questionTest">
        <TestContainer
          :question_test_id="questionTest.id"
          :student_id="selected_student_id"
        ></TestContainer>
      </template>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "EvaluateQuestionTest",
  components: {
    TestContainer: () => import("./TestContainer"),
    StudentCarousel: () => import("@/components/reusable/StudentCarousel"),
  },
  props: {
    question_test_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selected_student_id: null,
    };
  },
  computed: {
    ...mapGetters({
      questionTests: names.QUESTION_TESTS,
      evaluations: names.EVALUATIONS,
      sections: names.SECTIONS,
      users: names.USERS,
    }),
    questionTest() {
      return this.questionTests.find((x) => x.id == this.question_test_id);
    },
    evaluation() {
      if (!this.questionTest) return null;
      return this.evaluations.find((x) => x.id == this.questionTest.evaluation);
    },
    section() {
      if (!this.evaluation) return null;
      return this.sections.find((x) => x.id == this.evaluation.section);
    },
    students() {
      let list = [];
      if (!this.section) return list;
      this.section.students.forEach((student_id) => {
        const student = this.users.find((x) => x.id == student_id);
        if (student) list.push(student);
      });
      return list;
    },
  },
  methods: {
    slotStudentSelected(student_id) {
      this.selected_student_id = student_id;
    },
  },
};
</script>

<style>
</style>